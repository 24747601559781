<template>
   <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-title> Add New Remedies </v-card-title>
        <v-card-text>
                  <v-text-field
                    v-model="name"
                    label="Homely Remedy Name"
                    required
                  ></v-text-field>
                   <v-text-field
                    v-model="directive"
                    label="Directive"
                    required
                  ></v-text-field>
                   <v-text-field
                    v-model="description"
                    label="Description"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="totalQuantity"
                    label="Total Quantity"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="type"
                    label="Type"
                    required
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="mr-12">
                          <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="openDate"
                                label="Open Date"  
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="openDate" no-title scrollable
                              @input="menu1 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="ml-12 pl-2">
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="expiryDate"
                                label="Expiry Date"  
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="expiryDate" no-title scrollable
                              @input="menu2 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                    </v-col>
                  </v-row>
                  <v-select
                      label="Home"
                      v-model="home"
                      :items="allHomes"
                      item-text="name"
                      item-value="id"
        >
        </v-select>
                  <v-btn
                    color="warning"
                    @click="register"
                  >
                    Submit
                  </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
   </v-row>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
import moment from 'moment';

export default {
    name:'registerRemedy',
    data () {
      return {
        name: '',
        totalQuantity: '',
        user: '',
        directive: '',
        description: '',
        home: '',
        type:'',
        expiryDate: null,
        openDate: null,
        menu1: false,
        menu2: false,
        homeList: [],
      }
    },
    computed: {
        ...mapGetters([
           'getLoggedUser',
           'allHomes',
        ]),
    },
     created() {
       this.getLoggedInUser();
       this.get_home_list();
    },
    methods: {
      ...mapActions([
          'register_homely_remedy',
          'getLoggedInUser',
          'get_home_list',
      ]),
      register () {
        this.user = this.getLoggedUser.user.id;
        delete this.$data.menu1;
        delete this.$data.menu2;
        delete this.$data.homeList;
        this.$data.openDate = this.formatDate(this.$data.openDate);
        this.$data.expiryDate = this.formatDate(this.$data.expiryDate);
        this.register_homely_remedy({
          ...this.$data
        })
        /* eslint-disable */
        .then(response => {
            this.$router.push('/homelyRemedies')
        })
        .catch(error => {
            console.log(error)
        })
      },
       formatDate(date) {
        date = moment(date).format('DD-MM-YYYY HH:mm');
        return date
      }
    },
}
</script>

<style lang="stylus" scoped>

</style>